<template>
  <div class="nav-header">
    <el-row>
      <el-col :span="12">
        <div class="title">
          <i class="el-icon-s-home"></i>
          <span>
            <span class="mobile">{{ title }}-</span> 后台界面
          </span>
        </div>
      </el-col>

      <el-col :span="12">
        <div class="layout-right">
          <ul class="clear nav-ul">
            <li class="nav-item">
              <el-link type="primary" class="full-name">{{ fullName }}</el-link>
            </li>
            <li class="nav-item">
              <i class="el-icon-s-home" title="我的" @click="handleHome"></i>
            </li>
            <li class="nav-item">
              <i
                class="el-icon-refresh"
                title="刷新"
                @click="handleRefresh"
              ></i>
            </li>
            <li class="nav-item mobile">
              <i
                class="el-icon-switch-button"
                title="关闭"
                @click="handleClose"
              ></i>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import settings from "@/settings";

export default {
  computed: {
    ...mapGetters(["fullName"]),
    title() {
      return settings.title;
    },
  },
  methods: {
    handleRefresh() {
      window.location.reload();
    },
    handleHome() {
      window.open("/cms/home");
    },
    handleClose() {
      this.$confirm("是否要退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("logout");
        this.$store.dispatch("clearInfo");

        location.href = process.env.VUE_APP_LOGIN_URL;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-header {
  .layout-right {
    .nav-item {
      position: relative;
      display: inline-block;
      padding: 0 20px;
      cursor: pointer;

      i {
        font-size: 25px;
      }

      .full-name {
        margin-top: -10px;
      }
    }
  }
}

/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  .nav-header {
    .title {
      font-size: 1rem;
    }
    .mobile {
      display: none !important;
    }
    .layout-right {
      .nav-item {
        padding: 0 5px;
        i {
          font-size: 1rem;
        }

        .full-name {
          font-size: 0.6rem;
          margin-top: 0px;
        }
      }
    }
  }
}
</style>
